<template>
  <b-card :title="`${$t('deposits.list_deposits')}`">
    <b-card-text>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between mb-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modalAdd
          variant="primary"
        >
          <feather-icon
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span>{{ $t('deposits.button_new') }}</span>
        </b-button>
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="`${$t('deposits.field_search')}`"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ statusVariantLabel(props.row.status) }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'coin_fiat_deposit'">
            {{ props.row.coin_fiat_deposit.toUpperCase() }}
          </span>

          <span v-else-if="props.column.field === 'declared_value_deposit'">
            {{ formatPrice(props.row.declared_value_deposit) }}
          </span>

          <span v-else-if="props.column.field === 'created_at'">
            {{ props.row.created_at | moment('DD/MM/YYYY - H:mm:ss') }}
          </span>

          <span v-else-if="props.column.field === 'action'">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
              class="mr-1"
              size="sm"
              @click="visualizarItem(props.row.originalIndex)"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>{{ $t('deposits.actions.view') }}</span>
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-text>

    <b-modal
      id="modalAdd"
      centered
      :title="`${$t('deposits.form.request_deposit')}`"
      hide-footer
    >
      <b-card-text>
        <validation-observer ref="depositoForm">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="`${$t('deposits.form.usdt_amount')}`"
                  label-for="valorDeposito"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="valor"
                    rules="required|min:1"
                  >
                    <b-form-input
                      id="valorDeposito"
                      v-model.lazy="newDeposito.declared_value_deposit"
                      v-money="money"
                      name="valor"
                      type="text"
                      min="1"
                      :state="errors.length > 0 ? false : null"
                      placeholder="0,00"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <label for="wildcard">{{ $t('deposits.form.deposit_receipt') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="comprovante"
                  rules="required"
                >
                  <b-form-file

                    id="wildcard"
                    v-model="newDeposito.payment_voucher"
                    name="comprovante"
                    accept="image/*, .pdf"
                    :browse-text="`${$t('register.form.select_file')}`"
                    :placeholder="`${$t('register.form.no_file_selected')}`"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <label
                  for="wildcard"
                  class="mt-1"
                >{{ $t('deposits.form.notes') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="mensagem"
                  rules="required"
                >
                  <b-form-textarea
                    id="note"
                    v-model="newDeposito.note"
                    name="mensagem"
                    :placeholder="`${$t('deposits.form.message')}`"
                    rows="3"
                    max-rows="6"
                    maxlength="255"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mt-1"
                  block
                  :disabled="(parseFloat(newDeposito.declared_value_deposit) === 0 || enviando)"
                  @click="validationForm"
                >
                  {{ enviando ? $t('deposits.form.button_sending') : $t('deposits.form.button_submit') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modalView"
      centered
      :title="`${$t('deposits.modal_info.title')}`"
      hide-footer
    >
      <b-card-text v-if="renderView">
        <b-badge variant="dark">
          {{ viewItem.created_at | moment('DD/MM/YYYY - H:mm:ss') }}
        </b-badge>
        <b-badge
          variant="warning"
          class="mx-1"
        >
          {{ viewItem.ip }}
        </b-badge>
        <b-badge :variant="statusVariant(viewItem.status)">
          {{ statusVariantLabel(viewItem.status) }}
        </b-badge><br><br>
        <ul>
          <li>
            <strong>{{ $t('deposits.modal_info.coin') }}:</strong> {{ viewItem.coin_fiat_deposit.toUpperCase() }}
          </li>
          <li>
            <strong>{{ $t('deposits.modal_info.declared_value') }}:</strong> {{ viewItem.declared_value_deposit }}
          </li>
          <li>
            <strong>{{ viewItem.status === 2 ? $t('deposits.modal_info.denied_value') : $t('deposits.modal_info.final_value') }}:</strong> {{ viewItem.value_deposit }}
          </li>
          <li>
            <strong>{{ $t('deposits.modal_info.user_notes') }}:</strong> {{ viewItem.note }}
          </li>
          <li>
            <strong>{{ $t('deposits.modal_info.admin_notes') }}:</strong> {{ viewItem.note_admin }}
          </li>
        </ul>
        <p>
          <a
            :href="viewItem.payment_voucher !== null ? viewItem.payment_voucher : ''"
            target="_blank"
            class="font-weight-bold"
          >{{ $t('deposits.modal_info.view_receipt') }}</a>
        </p>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BFormFile,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import { depositosService } from '@/services/depositos'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BCard,
    BCardText,
    BFormFile,
    BFormInput,
    BForm,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    BBadge,
  },
  directives: {
    Ripple,
    mask,
    money: VMoney,
  },
  data() {
    return {
      depositosService: depositosService(this.$http),
      required,
      min,
      dir: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
      rows: [],
      searchTerm: '',
      default: {
        0: 'Aguardando Aprovação',
        1: 'Aprovado',
      },
      options: [
        { value: 'usd', text: 'USDT' },
      ],
      newDeposito: {
        coin_fiat_deposit: 'usd',
      },
      renderView: false,
      viewItem: {},
      urlApi: process.env.VUE_APP_IMG_BASE_URL,
      enviando: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('deposits.table_header.time'),
          field: 'created_at',
        },
        {
          label: this.$t('deposits.table_header.value'),
          field: 'declared_value_deposit',
          sortable: false,
        },
        {
          label: this.$t('deposits.table_header.coin'),
          field: 'coin_fiat_deposit',
        },
        {
          label: this.$t('deposits.table_header.status'),
          field: 'status',
        },
        {
          label: this.$t('deposits.table_header.action'),
          field: 'action',
          sortable: false,
        },
      ]
    },
    statusVariantLabel() {
      const statusLabel = {
        /* eslint-disable key-spacing */
        2: this.$t('deposits.deposit_status.denied'),
        1: this.$t('deposits.deposit_status.approved'),
        0: this.$t('deposits.deposit_status.pending'),
        /* eslint-enable key-spacing */
      }

      return status => statusLabel[status]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        2: 'light-danger',
        1: 'light-success',
        0: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.listItens()
  },
  methods: {
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async listItens() {
      const resultado = await this.depositosService.get()
      this.rows = resultado.data.deposit
    },
    async visualizarItem(id) {
      this.viewItem = await this.rows[id]
      this.renderView = await true
      this.$bvModal.show('modalView')
    },
    validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.depositoForm.validate().then(async success => {
        if (success) {
          this.enviando = true
          try {
            const arquivo = await toBase64(this.newDeposito.payment_voucher)

            const depositFinalValue = this.newDeposito.declared_value_deposit
              .replace(/\.*/g, '')
              .replace(',', '.')

            const data = {
              declared_value_deposit: depositFinalValue,
              note: this.newDeposito.note,
              coin_fiat_deposit: this.newDeposito.coin_fiat_deposit,
              payment_voucher: arquivo,
            }

            await this.depositosService.post(data)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comprovante enviado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.$bvModal.hide('modalAdd')
            this.listItens()
            this.newDeposito = {}
            this.enviando = false
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erro ao enviar comprovante.',
                text: 'NO MOMENTO não é possível enviar o pedido. Tente novamente.',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
  },
}
</script>
